.b-feedback{
    &__info{
        font-size: 18px;
    }
}

@include media-breakpoint-down(lg) {
    .b-feedback{
        &__info{
            font-size: 14px;
        }
    }
}