.b-image-list{
    &__item{

    }
}

@include media-breakpoint-down(md) {
    .b-image-list{
        &__item{
            flex-basis: 50%;
            width: 50%;
        }
    }
}