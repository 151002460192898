.b-top-mobile-sidebar{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 999;
    color: #000;
    transition: all 0.3s;
    overflow-y: scroll;
  
    &.active {
        left: 0;
    }
  

    &__dismiss {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;
    }

    & ul li a {
        color: #ffffff;
        font-size: 24px;
        &.nav-link{
            &:after{
                content: '';
                background-image: url('/assets/template/dist/img/tr_w.svg');
                width: 12px;
                height: 12px;
                display: inline-block;
                margin-left: 0.5rem;
              } 
        }
        &.nav-link-lang{
            &:after{
                transform: rotate(90deg);
              } 
        }
    }
  
    & ul li.active>a,
    a[aria-expanded="true"] {
        // color: #000;
        // background: #FBFBFB;
    }
  
}

