.banner{
    position: absolute;
    top: -70px;
    z-index: -1;
    width: 100%;
    // &:after {
    //     content: '';
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: rgba(1, 4, 7, 0.73);
    // }
}

@include media-breakpoint-down(lg) {
    .banner{
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 92.68%, #FFFFFF 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.57) 4.17%, rgba(0, 0, 0, 0) 14.58%), url('/assets/template/dist/img/banner.jpg');
        // background-position: top;
        // background-size: cover;
        // background-repeat: no-repeat;
        // height: 100vh;
        // margin: 0 auto;
        // position: relative;
        // overflow: hidden;
    }
}