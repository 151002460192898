.b-map{
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    height: 325px;
}


@include media-breakpoint-down(lg) {
    .b-map{
        &__wrapper{
            padding: 0;
        }
    }
}