.b-top-menu{
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 92.68%, #FFFFFF 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.57) 4.17%, rgba(0, 0, 0, 0) 14.58%), url('/assets/template/dist/img/banner.jpg');
    // background-position: top;
    // background-size: cover;
    // background-repeat: no-repeat;
    // height: 100vh;
    // margin: 0 auto;
    // position: relative;
    // overflow: hidden;
    // z-index: 1;

    z-index: 999;

    &-black{
        & .navbar-toggler-icon{
            background-image: url('/assets//template/dist/img/hamburg_black.svg');
            width: 23px;
            height: 27px;
        }
    }

    &-white{
        & a.nav-link{
            color: #fff;
        }
        & .navbar-toggler-icon{
            background-image: url('/assets/template/dist/img/hamburg_white.svg');
            width: 23px;
            height: 27px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .b-top-menu{
        // max-width: 100%;
    }
}