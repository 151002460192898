.b-about{
    &__img{

    }
}

@include media-breakpoint-down(lg) {
    .b-about{
        &__img{
            width: 100%;
        }
    }
}