.b-brand{
    &__item{
        box-shadow: 10px 10px 33px rgba(0, 0, 0, 0.15);
        border-radius: 20px;
        min-height: 455px;
        width: calc(50% - 10px);
    }
    &__list{
        gap: 20px 5px;
    }
    &__img{
        &-1{
        }
        &-2{
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &__btn{
        padding: 5px 33px 7px;
    }
}

@include media-breakpoint-down(lg) {
    .b-brand{
        &__item{
            min-height: auto;
            width: calc(100%);
        }
    }
}