// Size
$mainFontSize            : 16px;
$mainFontWeight          : normal;
$mainLineHeight          : 22px;


$theme-colors: (
  "white": #FFFFFF,
  "black": #000000,
  "gray": #F9F9F9,
  "gray-1": #808080,
  "gray-2": #777777,
);
