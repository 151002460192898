.b-review{
    &__item{

    }

    & ul.slick-dots{
        display: flex;
        margin: auto;
        justify-content: center;
        margin-top: 2rem;
        list-style:none;
        & li{
            margin-right: 1rem;
            & button{
                border: 0;
                background: transparent;
                color: #000;
                width: 38px;
                height: 38px;
            }
        }
        & li.slick-active{
            & button{
                border: 0;
                background: #000;
                color: #fff;
                border-radius: 50%;
                width: 38px;
                height: 38px;
            }
        }
        
    }
}

@include media-breakpoint-down(lg) {
    .b-review{
        &__img{
            width: 100%;
        }
    }
}