body{
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI';
    font-size: rem(16);
    line-height: rem(24);
  }
  
  @include media-breakpoint-down(lg) {
    body{
      font-size: rem(14);
      line-height: rem(24);
    }
  }
  
  *, *:before, *:after { box-sizing: border-box; }
  
  h1, h2, h3, h4, h5, h6 { margin: 0; }
  
  h1{ font-size: rem(30); }
  h2{ font-size: rem(28); }
  h3{ font-size: rem(26); }
  h4{ font-size: rem(24); }
  h5{ font-size: rem(22); }
  h6{ font-size: rem(20); }
  
  a{
    text-decoration: none;
    color:#000;
    &:hover{
      text-decoration: none;  
    }
  }

  @each $color, $value in $theme-colors {
    .text-#{$color}{
      color: theme-color($color);  
    }
  }
  
  @each $color, $value in $theme-colors {
    .bg-#{$color}{
      background-color: theme-color($color);  
    }
  }

@include media-breakpoint-up(lg) {
    .bg-lg-white{
      background-color: theme-color('white');  
    }
}
@include media-breakpoint-down(lg) {
  .bg-mobile-image-main{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 96.42%, #FFFFFF 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.57) 4.17%, rgba(0, 0, 0, 0) 14.58%), url('/assets/template/dist/img/main_mobile.jpg');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
}

@include media-breakpoint-down(md) {
  .logo{
    width: 135px; 
  }
}
  .section-title{
    font-family: 'Mont';
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;

    & a{
       &:after{
        content: '';
        background-image: url('/assets/template/dist/img/tr.svg');
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-left: 1.2rem;
      }   
    }

  }


  @include media-breakpoint-down(lg) {
    .section-title{
      font-size: 24px;
      line-height: 32px;
      & a{
        &:after{
         background-size: 15px 15px;
         width: 15px;
         height: 15px;
         margin-left: 0.7rem;
       }   
     }
    }
  }

  .bg-image{
    background-image: url('/assets/template/dist/img/2.png');
  }
  .bg-image-black{
    background: url('/assets/template/dist/img/footer-min.png'), radial-gradient(346.48px at 50% 43.75%, #202020 0%, #000000 100%);
  }

  .bg-image-partners{
    background-image: url('/assets/template/dist/img/handshake.png');
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }

  .btn-default{
    color: #ffffff;
    box-shadow: 10px 10px 33px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 10px 33px 12px;
    background-image: url('/assets/template/dist/img/button.svg');
    background-repeat: no-repeat;
    background-size: cover;
   // background: radial-gradient(36.56px at 49.74% -4.44%, #0094D4 0%, #003F8C 100%);
    transition: 0.5 all;
    border: none;
    &:hover{
      color:#ffffff;
      box-shadow: 10px 10px 33px rgba(0, 0, 0, 0.35);
      transition: 0.5 all;
    }
    // background-image: linear-gradient(to right,#004D98,#0069B2,#0075B9,#007EC3,#0075B9,#0069B2,#004D98);
  }

  @include media-breakpoint-down(lg) {
    .btn-default{
      padding: 7px 25px 9px;
    }
  }

  .icon-scroll{
    width: 30px;
    height: 45px;
    margin-left: -20px;
    top: 80%;
    margin-top: 70px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
    opacity: 0.4;

    position: absolute;
    left: 50%;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: #fff;
        margin-left: -4px;
        top: 8px;
        border-radius: 4px;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scroll;

        position: absolute;
        left: 50%;
    }
  }

  @-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(46px);
        transform: translateY(46px);
    }
}
@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(26px);
        transform: translateY(26px);
    }
}

@include media-breakpoint-down(lg) {
  .icon-scroll{
    top: 70%;
  }
}

@include media-breakpoint-up(lg) {
  .b-top-menu .navbar-expand-lg .navbar-nav .nav-link{
    padding-right: .9rem !important;
    padding-left: .9rem !important;
  }
}

div.pagination {
	display: flex;
	justify-content: center;
	margin-top: 60px;
}
.pagination {
	.page-item {
		.page-link {
			font-family: Mont;
			border: none;
			color: #000;
			border-radius: 50%;
			background-color: inherit;
			margin: auto 5px;
			height: 36px;
			width: 36px;
			text-align: center;
		}
		.page-link.prev {
			font-size: 12px !important;
			padding-top: 10px !important;
		}
		.page-link.next {
			font-size: 12px !important;
			padding-top: 10px !important;
		}
		&:first-child {
			.page-link {
				border-radius: 50%;
			}
		}
		&:last-child {
			.page-link {
				border-radius: 50%;
			}
		}
	}
	.page-item.disabled {
		.page-link {
			font-family: Mont;
			border: none;
			color: #000;
			border-radius: 50%;
			background-color: inherit;
			margin: auto 5px;
			height: 36px;
			width: 36px;
			text-align: center;
			color: #9b9b9b;
		}
	}
	.page-item.active {
		.page-link {
      background-color: #000;
      color: #fff;
		}
	}
	.page-link {
		&:focus {
			box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 0.25);
		}
	}
}
.header-about {
	padding-bottom: 80px;
	background-size: 265px !important;
	.icon-scroll {
		bottom: 60px;
		position: absolute;
		top: auto;
		margin-top: auto;
	}
}
.b-portrait__title {
	font-family: Segoe UI;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 40px;
}
@media (max-width: 720px) {
	.header-about {
		.icon-scroll {
			background-image: url(../img/scroll.png);
			border: none;
			border-radius: 0;
			box-shadow: none;
			background-size: cover;
			width: 50px;
			background-position: center;
			&:before {
				display: none;
			}
		}
	}
}